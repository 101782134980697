.checkoutGiftCard .sidebarAccordionTitle[aria-controls],
#sidebarCheckout .checkoutGiftCard .sidebarAccordionTitle[aria-controls] {
  min-width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.checkoutGiftCard .sidebarAccordionTitle,
.checkoutGiftCard .sidebarAccordionTitle[aria-expanded],
#sidebarCheckout .checkoutGiftCard .sidebarAccordionTitle,
#sidebarCheckout .checkoutGiftCard .sidebarAccordionTitle[aria-expanded] {
  font-size: 16px;
  cursor: pointer;
  margin: 30px 0;
}
.checkoutGiftCard .giftCardRowTitle,
#sidebarCheckout .checkoutGiftCard .giftCardRowTitle {
  margin-bottom: 0;
}
.checkoutGiftCard .giftCardRowTitle .custom-control-label,
#sidebarCheckout .checkoutGiftCard .giftCardRowTitle .custom-control-label {
  padding-top: 0;
  margin-bottom: 0;
}
.checkoutGiftCard .custom-control-label,
#sidebarCheckout .checkoutGiftCard .custom-control-label {
  background: transparent;
}
.checkoutGiftCard .small,
#sidebarCheckout .checkoutGiftCard .small {
  font-size: 13px;
  font-family: "Poppins", sans-serif;
}
.checkoutGiftCard .emailWarning,
#sidebarCheckout .checkoutGiftCard .emailWarning {
  margin-bottom: 10px;
  line-height: 120%;
}
.checkoutGiftCard .giftCardRow,
#sidebarCheckout .checkoutGiftCard .giftCardRow {
  margin-bottom: 0;
}
.checkoutGiftCard .giftCardModalLink,
#sidebarCheckout .checkoutGiftCard .giftCardModalLink {
  line-height: 140%;
}
.checkoutGiftCard .giftCardModalLink strong,
#sidebarCheckout .checkoutGiftCard .giftCardModalLink strong {
  display: block;
  text-decoration: underline;
  font-weight: bold;
  text-decoration: underline;
}
.checkoutGiftCard .panel-white,
#sidebarCheckout .checkoutGiftCard .panel-white {
  font-size: 12px;
  background: rgba(255, 255, 255, 0.5);
  padding: 16px 20px;
}
.checkoutGiftCard .giftCardRowTitle .custom-checkbox,
#sidebarCheckout .checkoutGiftCard .giftCardRowTitle .custom-checkbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.checkoutGiftCard .giftCardRowTitle .labelGiftCard,
#sidebarCheckout .checkoutGiftCard .giftCardRowTitle .labelGiftCard {
  font-size: 14px;
}
@media (min-width: 1200px) {
.checkoutGiftCard .giftCardRowTitle .labelGiftCard,
#sidebarCheckout .checkoutGiftCard .giftCardRowTitle .labelGiftCard {
    font-size: 18px;
}
}